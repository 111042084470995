import router from "@/services/Router";

export class EsquireClient {
  private baseUrl = process.env.VUE_APP_ESQUIRE_HOST;

  private defaultHeaders = {
    Accept: "application/json"
  } as {
    Accept: string,
    Authorization: string
  };

  public async fetch(createUrl: (baseUrl: string) => URL, options: RequestInit = {}) {
    const headers = {
      ...this.defaultHeaders,
      ...options.headers as Record<string, string>
    };

    const fetchOptions: RequestInit = {
      ...options,
      headers,
      credentials: "include"
    };

    const url = createUrl(this.baseUrl);

    return fetch(url.toString(), fetchOptions);
  }
  public async logOutIfUnauthorized(status: number) {
    if (status === 401) {
      await this.logOut();
    }
  }

  public async logOut(){
    localStorage.clear();
    await router.routeTo("auth");
  }
}

export default new EsquireClient();
