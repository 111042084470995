import { defineStore } from "pinia";
import { Ref, ref } from "vue";

export default defineStore("sessionStore",
  () => {
    const sessionId = ref(null) as Ref<null|string>;

    function setSession(value: string): void {
      sessionId.value = value;
    }

    return {
      sessionId,
      setSession
    };
  });
