import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import pinia from './plugins/pinia';
import router from './router';
import routerPlugin from "@/plugins/routerPlugin";

import 'vuetify/dist/vuetify.css';

createApp(App)
  .use(vuetify)
  .use(router)
  .use(routerPlugin)
  .use(pinia)
  .mount('#app');
