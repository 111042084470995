// router/index.ts
import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import useStore from "@/stores";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/views/LoginView.vue')
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/building/:id',
        name: 'building',
        component: () => import('@/views/BuildingView.vue')
    },
    {
        path: '/building/:id/arrivals',
        name: 'arrivals',
        component: () => import('@/views/ArrivalsView.vue')
    },
    {
        path: '/building/:id/faqs',
        name: 'faqs',
        component: () => import('@/views/FaqsView.vue')
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

/*router.beforeEach((to, from, next) => {
    const store = useStore();
    const sessionId = localStorage.getItem("sessionId");
    if (to.name === "auth") {
        next();
    } else if (!sessionId && !store.session.sessionId) {
        store.app.authRedirectsTo = to.name?.toString() ?? "";

        next({ name: "auth" });
    } else {
        next();
    }
});*/

export default router;
