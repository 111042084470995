import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("app",
  () => {
    const drawerIsToggled = ref(false);
    const authRedirectsTo = ref("home");

    return {
      authRedirectsTo,
      drawerIsToggled
    };
  });
