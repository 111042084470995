import {EsquireClient} from "@/types/clients/esquire";
import LoginData from "@/types/clients/esquire/data/general/login-data";
import {getCurrentInstance} from "vue";
import LogInPayload from "@/types/clients/esquire/payloads/log-in-payload";

export default class LogOut {
    constructor(private client: EsquireClient) {
    }

    private instance = getCurrentInstance();
    private router = this.instance?.appContext.config.globalProperties.$routerUtils;

    public async send() {
        const response = await this.client.fetch(
            (baseUrl: string) => new URL(`api/v1/callcenter/logout`, baseUrl), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: 'include'
            }
        );

        if (!response.ok) {
            if (response.status === 403) {
                localStorage.clear();
                await this.router.routeTo("auth");
            } else {
                return new LoginData({
                    status: "unavailable",
                    routes: []
                });
            }
        }
        const result = await response.json();
        return new LoginData(result);
    }
}
