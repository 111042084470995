<template>
  <v-app-bar color="primary">
    <v-app-bar-title>Be-Housing CallCenter App</v-app-bar-title>
    <template v-slot:append>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="logOut">
              <v-btn append-icon="mdi-logout" style="width: 100%">
                Log out
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
import esquire from "@/types/clients/esquire";
import LogOut from "@/types/clients/esquire/requests/auth/log-out";
import {getCurrentInstance} from "vue";

const instance = getCurrentInstance();
const router = instance?.appContext.config.globalProperties.$routerUtils;

async function logOut() {
  const request = new LogOut(esquire);
  await request.send();
  await router.routeTo("auth");

}
</script>

<style scoped>

</style>