import router from "@/router";
import {RouteParamsRaw, useRoute} from "vue-router";
import useStore from "@/stores";

class Router {
  async routeTo(name: string, params: RouteParamsRaw = {}, query: RouteParamsRaw = {}) {
    await router.push({ name, params, query });
  }

  getBreadCrumbs() {
    const fullPathSegments = router.currentRoute.value.fullPath.split("/");

    const filteredSegments = fullPathSegments.filter((segment) => segment.length !== 2);

    return filteredSegments.map((el) => ({
      name: el === "" ? "home" : el,
    }));
  }
  getLoginParameter() {
    return this.getQueryParams().login?.toString() ?? "";
  }
  refreshCurrentPage() {
    router.go(0);
  }

  getQueryParams() {
    const route = useRoute();
    return route.query;
  }

  saveSignedRoutes(sessionId: string, routes: Array<string>) {
    const store = useStore();
    store.session.setSession(sessionId.toUpperCase());
    const keys = Object.keys(routes);
    const values = Object.values(routes);
    localStorage.setItem("sessionId", sessionId);
    keys.forEach((item, index) => {
      localStorage.setItem(item, values[index]);
    });
  }
}

export default new Router();
